/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

export enum AiAgentConversationAnswerFeedbackOption {
  Resolved = 'Resolved',
  NotResolved = 'NotResolved',
  EasyToFollow = 'EasyToFollow',
  HardToUnderstand = 'HardToUnderstand',
  Other = 'Other'
}
