import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CompanyStore } from '../../portal/store/company-store';
import { ChargeBeeFeature } from '../../../api/models/charge-bee-feature';
import { CustomerEntitlementResult } from '../../../api/models/customer-entitlement-result';

@Injectable({
  providedIn: 'root'
})
export class FeatureVisibilityService {
  constructor(private companyStore: CompanyStore) {}

  /**
   * Evaluate if the feature is valid
   * @param feature
   */
  public hasAccessToFeature(feature: ChargeBeeFeature): Observable<boolean> {
    return this.companyStore.currentCompany$.pipe(
      map((c) => {
        return c.companyPaymentInfo.entitlements.some(
          (r) =>
            r.chargeBeeFeature === feature && r.customerEntitlement.isEnabled && r.customerEntitlement.value === 'true'
        );
      })
    );
  }

  public evaluateAccessToFeature(
    feature: ChargeBeeFeature,
    entitlement: (result: CustomerEntitlementResult) => boolean
  ): Observable<boolean> {
    return this.companyStore.currentCompany$.pipe(
      map((c) => {
        const foundEntitlement = c.companyPaymentInfo.entitlements.find(
          (r) => r.chargeBeeFeature === feature && r.customerEntitlement.isEnabled
        );

        // Return false if no entitlement found, otherwise evaluate the customerEntitlement
        return foundEntitlement ? entitlement(foundEntitlement.customerEntitlement) : false;
      })
    );
  }
}
