/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { EmissionFactorDetailResponse } from '../models/emission-factor-detail-response';
import { getAllByEmissionFactorIds } from '../fn/emission-factor-detail/get-all-by-emission-factor-ids';
import { GetAllByEmissionFactorIds$Params } from '../fn/emission-factor-detail/get-all-by-emission-factor-ids';

@Injectable({ providedIn: 'root' })
export class EmissionFactorDetailService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `v1EmissionFactorDetailGetAllByEmissionFactorIdsPost()` */
  static readonly V1EmissionFactorDetailGetAllByEmissionFactorIdsPostPath = '/v1/emission-factor-detail/get-all-by-emission-factor-ids';

  /**
   * Retrieves a collection of emission factor details based on the provided emission factor IDs.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllByEmissionFactorIds()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getAllByEmissionFactorIds$Response(params?: GetAllByEmissionFactorIds$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<EmissionFactorDetailResponse>>> {
    return getAllByEmissionFactorIds(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a collection of emission factor details based on the provided emission factor IDs.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllByEmissionFactorIds$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getAllByEmissionFactorIds(params?: GetAllByEmissionFactorIds$Params, context?: HttpContext): Observable<Array<EmissionFactorDetailResponse>> {
    return this.getAllByEmissionFactorIds$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<EmissionFactorDetailResponse>>): Array<EmissionFactorDetailResponse> => r.body)
    );
  }

}
