/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

export enum ChargeBeeFeature {
  EmployeeSurvey = 'EmployeeSurvey',
  TargetSettingDecarbonisationPlan = 'TargetSettingDecarbonisationPlan',
  AccountingIntegration = 'AccountingIntegration',
  EmissionsBySupplier = 'EmissionsBySupplier',
  AutomatedReport = 'AutomatedReport',
  Certificates = 'Certificates',
  AuditTrail = 'AuditTrail',
  Benchmarking = 'Benchmarking',
  EmissionsByGhgCategorySubCategory = 'EmissionsByGhgCategorySubCategory',
  EmissionsByScope = 'EmissionsByScope',
  QuarterlyReporting = 'QuarterlyReporting',
  ActivityScope3 = 'ActivityScope3',
  ActivityScope12 = 'ActivityScope12',
  PacIncluded = 'PacIncluded',
  Entities = 'Entities',
  FullTimeEmployees = 'FullTimeEmployees'
}
