/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

export enum BadgeCertificateType {
  Measure = 'Measure',
  Engage = 'Engage',
  Report = 'Report',
  Reduce = 'Reduce'
}
