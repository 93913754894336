/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { BadgeTemplateResponse } from '../models/badge-template-response';
import { disabledPreviewAsPng$Json } from '../fn/badge-template/disabled-preview-as-png-json';
import { DisabledPreviewAsPng$Json$Params } from '../fn/badge-template/disabled-preview-as-png-json';
import { disabledPreviewAsPng$Png } from '../fn/badge-template/disabled-preview-as-png-png';
import { DisabledPreviewAsPng$Png$Params } from '../fn/badge-template/disabled-preview-as-png-png';
import { flexiblePreview$Json } from '../fn/badge-template/flexible-preview-json';
import { FlexiblePreview$Json$Params } from '../fn/badge-template/flexible-preview-json';
import { flexiblePreview$Xml } from '../fn/badge-template/flexible-preview-xml';
import { FlexiblePreview$Xml$Params } from '../fn/badge-template/flexible-preview-xml';
import { preview$Json } from '../fn/badge-template/preview-json';
import { Preview$Json$Params } from '../fn/badge-template/preview-json';
import { preview$Xml } from '../fn/badge-template/preview-xml';
import { Preview$Xml$Params } from '../fn/badge-template/preview-xml';
import { previewAsPng$Json } from '../fn/badge-template/preview-as-png-json';
import { PreviewAsPng$Json$Params } from '../fn/badge-template/preview-as-png-json';
import { previewAsPng$Png } from '../fn/badge-template/preview-as-png-png';
import { PreviewAsPng$Png$Params } from '../fn/badge-template/preview-as-png-png';
import { search } from '../fn/badge-template/search';
import { Search$Params } from '../fn/badge-template/search';

@Injectable({ providedIn: 'root' })
export class BadgeTemplateService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `v1BadgeTemplateSearchGet()` */
  static readonly V1BadgeTemplateSearchGetPath = '/v1/badge-template/search';

  /**
   * Get badge templates.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `search()` instead.
   *
   * This method doesn't expect any request body.
   */
  search$Response(params?: Search$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<BadgeTemplateResponse>>> {
    return search(this.http, this.rootUrl, params, context);
  }

  /**
   * Get badge templates.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `search$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  search(params?: Search$Params, context?: HttpContext): Observable<Array<BadgeTemplateResponse>> {
    return this.search$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<BadgeTemplateResponse>>): Array<BadgeTemplateResponse> => r.body)
    );
  }

  /** Path part for operation `v1BadgeTemplatePreviewPost()` */
  static readonly V1BadgeTemplatePreviewPostPath = '/v1/badge-template/preview';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `preview$Xml()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  preview$Xml$Response(params?: Preview$Xml$Params, context?: HttpContext): Observable<StrictHttpResponse<Blob>> {
    return preview$Xml(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `preview$Xml$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  preview$Xml(params?: Preview$Xml$Params, context?: HttpContext): Observable<Blob> {
    return this.preview$Xml$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `preview$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  preview$Json$Response(params?: Preview$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Blob>> {
    return preview$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `preview$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  preview$Json(params?: Preview$Json$Params, context?: HttpContext): Observable<Blob> {
    return this.preview$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

  /** Path part for operation `v1BadgeTemplatePreviewAsPngPost()` */
  static readonly V1BadgeTemplatePreviewAsPngPostPath = '/v1/badge-template/preview-as-png';

  /**
   * Preview as PNG.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `previewAsPng$Png()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  previewAsPng$Png$Response(params?: PreviewAsPng$Png$Params, context?: HttpContext): Observable<StrictHttpResponse<Blob>> {
    return previewAsPng$Png(this.http, this.rootUrl, params, context);
  }

  /**
   * Preview as PNG.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `previewAsPng$Png$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  previewAsPng$Png(params?: PreviewAsPng$Png$Params, context?: HttpContext): Observable<Blob> {
    return this.previewAsPng$Png$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

  /**
   * Preview as PNG.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `previewAsPng$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  previewAsPng$Json$Response(params?: PreviewAsPng$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Blob>> {
    return previewAsPng$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Preview as PNG.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `previewAsPng$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  previewAsPng$Json(params?: PreviewAsPng$Json$Params, context?: HttpContext): Observable<Blob> {
    return this.previewAsPng$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

  /** Path part for operation `v1BadgeTemplateDisabledPreviewAsPngPost()` */
  static readonly V1BadgeTemplateDisabledPreviewAsPngPostPath = '/v1/badge-template/disabled-preview-as-png';

  /**
   * Preview as PNG.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `disabledPreviewAsPng$Png()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  disabledPreviewAsPng$Png$Response(params?: DisabledPreviewAsPng$Png$Params, context?: HttpContext): Observable<StrictHttpResponse<Blob>> {
    return disabledPreviewAsPng$Png(this.http, this.rootUrl, params, context);
  }

  /**
   * Preview as PNG.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `disabledPreviewAsPng$Png$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  disabledPreviewAsPng$Png(params?: DisabledPreviewAsPng$Png$Params, context?: HttpContext): Observable<Blob> {
    return this.disabledPreviewAsPng$Png$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

  /**
   * Preview as PNG.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `disabledPreviewAsPng$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  disabledPreviewAsPng$Json$Response(params?: DisabledPreviewAsPng$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Blob>> {
    return disabledPreviewAsPng$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Preview as PNG.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `disabledPreviewAsPng$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  disabledPreviewAsPng$Json(params?: DisabledPreviewAsPng$Json$Params, context?: HttpContext): Observable<Blob> {
    return this.disabledPreviewAsPng$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

  /** Path part for operation `v1BadgeTemplateFlexiblePreviewPost()` */
  static readonly V1BadgeTemplateFlexiblePreviewPostPath = '/v1/badge-template/flexible-preview';

  /**
   * Flexible Preview by Measurement Profile.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `flexiblePreview$Xml()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  flexiblePreview$Xml$Response(params?: FlexiblePreview$Xml$Params, context?: HttpContext): Observable<StrictHttpResponse<Blob>> {
    return flexiblePreview$Xml(this.http, this.rootUrl, params, context);
  }

  /**
   * Flexible Preview by Measurement Profile.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `flexiblePreview$Xml$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  flexiblePreview$Xml(params?: FlexiblePreview$Xml$Params, context?: HttpContext): Observable<Blob> {
    return this.flexiblePreview$Xml$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

  /**
   * Flexible Preview by Measurement Profile.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `flexiblePreview$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  flexiblePreview$Json$Response(params?: FlexiblePreview$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Blob>> {
    return flexiblePreview$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Flexible Preview by Measurement Profile.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `flexiblePreview$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  flexiblePreview$Json(params?: FlexiblePreview$Json$Params, context?: HttpContext): Observable<Blob> {
    return this.flexiblePreview$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

}
