/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

export enum CurrentFeatureFlags {
  MasterSuppliers = 'MasterSuppliers',
  DeveloperTools = 'DeveloperTools',
  AiAssistant = 'AiAssistant',
  QuarterMp = 'QuarterMp',
  NewTargetYear = 'NewTargetYear',
  Refrigerants = 'Refrigerants',
  AccessToDisabledAiTools = 'AccessToDisabledAiTools',
  BadgeCertificates = 'BadgeCertificates'
}
