export function slugify(str: string) {
  return str
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, '')
    .replace(/[\s_-]+/g, '-')
    .replace(/^-+$/g, '');
}

/**
 * Replaces UUIDs in URLs with indexed placeholders id1, id2, etc.
 * @param url The URL containing UUIDs to replace
 * @return The URL with UUIDs replaced by placeholders
 */
export function anonymizeUrlIds(url: string): string {
  // UUID regex pattern (standard UUID format)
  const uuidRegex = /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/gi;

  let idCounter = 1;

  return url.replace(uuidRegex, () => `id${idCounter++}`);
}
